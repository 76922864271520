export default [
  {
    title: "What is a domain name?",
    content:
      "A domain name is your website name, the address where Internet users can access your website. used for finding and identifying computers on the Internet.",
  },
  {
    title: "Is My Domain Name Available?",
    content:
      "if A domain name is already claimed by someone else, it can’t be registered even if it’s not actually being used, but it’s easy to find out if your domain name is available in the form you want. the most reliable way to check for the availability of a domain name is to use a domain checker that can scan all unavailable names.",
  },

  {
    title: "How Should I Choose My Domain Name?",
    content:
      "Choose a domain name that is easy to type, Avoid numbers and hyphens and keep it short. Try using keywords that describe your businessHosting and the services you offer. make sure to choose Extension that works for your businessHosting. NOTE :Extensions are suffixes, such as .com or .net, at the end of web addresses.",
  },

  {
    title: "Why Do I Need a Domain Name?",
    content:
      "A domain name is a valuable marketing and search tool that should successfully lead customers to your site. Careful deliberation and research should be applied, as it might be the most important decision you make when carving out your slice of the online market.",
  },
  {
    title: "How Do Domains Work?",
    content:
      "When you enter a domain name in your web browser, it first sends a request to a global network of servers that form the Domain Name System (DNS).\nThese servers then look up for the name servers associated with the domain and forward the request to those name servers.\nThese name servers are computers managed by your hosting company. Your hosting company will forward your request to the computer where your website is stored.\nThis computer is called a web server. It has special software installed (Apache, Nginx are two popular web server software).The web server now fetches the web page and pieces of information associated with it.\nFinally, it then sends this data back to the browser.",
  },
];
