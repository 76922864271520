export default [
  {
    image: "DNS.svg",
    imageAlt: "Easy DNS Management with Hostylus",
    title: "DNS Management",
    description:
      "Once you use our domain name lookup and find a suitable web address, you can manage your site through a simple interface",
  },
  {
    image: "auto renewal.svg",
    imageAlt: "Auto-Renewal feature on Hostyluss",
    title: "Auto-Renewal",
    description:
      "Protect your domain from expiring and accidentally being lost by enabling auto-renewal. Switch back to manual renewal at anytime. ",
  },
  {
    image: "Dlock.svg",
    imageAlt: "Domain Lock feature on Hostylus",
    title: "Domain Lock",
    description:
      "Once you find your perfect domain, lock it down to prevent unauthorized transfers. Unlock it for transfers at anytime. ",
  },
];
