import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Accordion from "../components/accordion";
import ImageRight from "../components/imageRight";
import HomeDomainSearchInput from "../components/HomeDomainSearchInput";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import Features from "../DB/Domains-features";
import Feature from "../components/featureBlock";
import AccordionContent from "../DB/Accordion-FAQ";
import Image from "../components/imageProcessing";
import Background from "../images/domains_header.png";

const LoadingIndicator = () => {
	const { promiseInProgress } = usePromiseTracker();

	return (
		promiseInProgress && (
			<div
				style={{
					width: "100%",
					height: "100",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Loader type="ThreeDots" color="#333" height="40" width="60" />
			</div>
		)
	);
};

function domains() {
	return (
		<Layout>
			<SEO
				keywords={[
					"domain google",
					"domain",
					"domain name",
					"domain name search",
					"domain buy",
					"domain lookup",
					"domain whois",
					"domain search",
					"domain whois lookup",
					"domain registrar",
					"domain registration",
					"domain check",
					"domain checker",
					"domain with email",
					"domain name purchase",
					"domain website",
					"domain name checker",
					"domain for sale",
					"domain name lookup",
					"domain examples",
					"domain transfer",
					"domain vs hosting",
					"domain versus hosting",
				]}
				title="Domains"
				image={"/hostylus-card-preview.jpg"}
				description="Pick your domain address & manage it through a simplified user interface. Set your renewal options to manual or automated and lock them to prevent data breaches."
			/>
			<section
				className="py-32"
				style={{
					backgroundImage: `url(${Background})`,
					backgroundPosition: " top center",
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					backgroundColor: "#F8F8F8",
				}}
			>
				<div className="max-w-4xl mx-auto p-8 mobile:p-3">
					<h1 className="text-2xl text-center font-bold text-black">
						Looking for your identity to be named?
					</h1>
					<h6 className="text-md mb-8 mt-2 text-center text-black">
						We&apos;ll generate it for you!
					</h6>
					<div id="domainSearch">
						<HomeDomainSearchInput
						attr="text-black"
						color="#222"
						/>
					</div>
					<LoadingIndicator />
					<div id="results"></div>
				</div>
			</section>
			<ImageRight
				image={
					<Image
						image={"ssl.png"}
						alt={"blue icon with a smaller ssl black icon inside of it  "}
					/>
				}
				header={"Website Security Shop SSL Certificates"}
				desc={
					"Protect your website and your visitors from hacks and data breaches with a secure connection"
				}
				hideLearnMore={"hidden"}
				btnLink="#"
			/>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3 tablet:px-6 laptop:px-8">
				<h2 className="text-4xl text-black font-bold text-center">
					Domain Name Features
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3 mobile:px-6">
					A domain name tells the world who you are and what you do. Search for
					a name, word, or phrase and we’ll help you find the perfect domain
					name to own your piece of the internet.
				</h4>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-stretch mt-6 mobile:grid-cols-1 tablet:grid-cols-1 tablet:px-16 py-8">
					{Features.map((items, key) => (
						<Feature
							key={key}
							image={items.image}
							header={items.title}
							desc={items.description}
							alt={items.title}
						/>
					))}
				</div>
			</section>
			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl mobile:px-2">
					<h2 className="text-center text-black mb-16 text-4xl font-bold uppercase">
						Frequently Asked Questions
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
		</Layout>
	);
}
export default domains;
