import React from "react";
import Accordion from "../components/accordion_index";
import "../styles/accordion-style.css";
import PropTypes from "prop-types";
function acc(props) {
  return (
    <Accordion title={props.title}>
      <hr className="mb-4" />
      <div>{props.content}</div>
    </Accordion>
  );
}
export default acc;
acc.PropTypes = {
  title: PropTypes.string.isRequired, // must be a string and defined
  content: PropTypes.any.isRequired, // must be a string and defined
};
